import { DragEventHandler, ForwardedRef, forwardRef, useRef, useState } from "react";
import { Ask } from "../components/ask/ask";
import { Login } from "../components/auth/login";
import { CsvDatasource } from '../utils/CsvUtils';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { LoggedInEventType, eventLogger } from 'datayaki-utils';
import { addDatasources, dashiSlice, removeDatasource } from "../app/controllers/dashi-slice";
import { Button, IconButton, Typography, Avatar, Paper, Popover, Tooltip, CircularProgress } from "@mui/material";
import { Delete as DeleteIcon, Help } from "@mui/icons-material";
import './demo.css';
import { Datasource } from "../dal/do/Datasource";
import { DatasourceDO } from 'datayaki-api';
import { logout } from "../app/controllers/auth-slice";
import Gravatar from "react-awesome-gravatar";
import { XlsxUtils } from "../utils/XlsUtils";
import { DatayakiWidgetEl } from "../components/dashi/widget/Widget";
import { AskMessage, getWidgetFunction } from '../app/controllers/ask-slice';
import { ModalType, uiSlice } from '../app/controllers/ui-slice';
import { BalanceOutlined, FeedbackOutlined } from '@mui/icons-material';
import { auth } from "../firebase";
import * as React from 'react';
import { Steps, Step } from 'intro.js-react';
import "intro.js/introjs.css";

const { REACT_APP_VERSION, REACT_APP_COPYRIGHT } = process.env;

const ACCEPTABLE_FILE_TYPES = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

enum DemoIntroStepName {
  ADD_DATASOURCES = 0,
  ASK_QUESTION,
  ADD_WIDGET,
  DONE
}
export const DemoIntroContext = React.createContext({currentStep: DemoIntroStepName.ADD_DATASOURCES, completeIntroStep: (step: DemoIntroStepName) => {}})

const demoIntroSteps: Map<DemoIntroStepName, Step[]> = new Map(
  [
    [DemoIntroStepName.ADD_DATASOURCES, [
      {
        element: ".dashi_datasource_uploader",
        title: "Start here!",
        intro: "Click here to add your dataset or drag and drop a file"
      }
    ]],
    [DemoIntroStepName.ASK_QUESTION, [
      {
        element: ".ask_container",
        intro: "Click here to ask a question"
      }
    ]],
    [DemoIntroStepName.ADD_WIDGET, [
      {
        element: ".ask_add_widget_buttonr",
        intro: "Click here to add the widget to your dashboard"
      }
    ]],
  ]
);

export function Demo() {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  // const widgetFnCount = useAppSelector(state => state.ask.widgetFnCount);
  // const widgets = useAppSelector(state => state.dashi.widgets);
  // const datasources = useAppSelector(state => state.dashi.datasources);
  // const askDisplay = useAppSelector(state => state.ask.enabled && state.ask.display);
  // const modalStack = useAppSelector(state => state.ui.modalStack);

  const steps = useRef<Steps | null>(null);
  const [introEnabled, setIntroEnabled] = useState<boolean>(true);
  // const [hintsEnabled, setHintsEnabled] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<DemoIntroStepName>(DemoIntroStepName.ADD_DATASOURCES);
  const [completed, setCompleted] = useState<boolean>(false);
  const [introSteps, setIntroSteps] = useState<Step[]>(demoIntroSteps.get(DemoIntroStepName.ADD_DATASOURCES) ?? [] as Step[]);
  // const [introHints, setIntroHints] = useState<Hint[]>(
  //   [
  //     {
  //       element: ".ask_send_button",
  //       hint: "click here or press enter to send.",
  //       hintPosition: "middle-top"
  //     },
  //     {
  //       element: ".ask_add_widget_button",
  //       hint: "click here to add widget to dashboard.",
  //       hintPosition: "middle-left"
  //     }
  //   ]
  // );

  const completeIntroStep = (step: DemoIntroStepName) => {
    const nextStep = step + 1;
    if (nextStep > currentStep) {
      setCurrentStep(nextStep);
      if (nextStep !== DemoIntroStepName.DONE) {
        steps.current?.introJs.exit();
        setIntroSteps(demoIntroSteps.get(nextStep) ?? [] as Step[]);
        if (!completed)
          setTimeout(() => {
              // steps.current?.introJs.goToStep(0);
              steps.current?.introJs.start();
          }, 100);
      } else {
        setCompleted(true);
        steps.current?.introJs.exit();
        setIntroSteps(Array.from(demoIntroSteps.values()).flatMap(x => x));
      }
    }
  }

  React.useEffect(() => {
    // Ensure the Tawk object has initalized
    if (window.$_Tawk && window.$_Tawk.init) {
      window.Tawk_API?.hideWidget();
    } else {
      // If the Tawk object didn't initilize, use a differnt method of loading
      if( window.Tawk_API ){
        window.Tawk_API.onLoad = function(){
          window.Tawk_API?.hideWidget();
        };
      }
    }
    
    return () => {
      if (window.$_Tawk && window.$_Tawk.init) {
        window.Tawk_API?.showWidget();
      } else {
        // If the Tawk object didn't initilize, use a differnt method of loading
        if( window.Tawk_API ){
          window.Tawk_API.onLoad = function(){
            window.Tawk_API?.showWidget();
          };
        }
      }  
    };
  }, []);

  const renderNode = isLoggedIn ? (
    <DemoIntroContext.Provider value={{currentStep, completeIntroStep}}>
      <div id="app-container" style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
        <Steps
          ref={steps}
          enabled={introEnabled}
          steps={introSteps}
          initialStep={0}
          onExit={(stepIndex) => setIntroEnabled(false)}
          onStart={()=> steps.current?.updateStepElement(0)}
          options={{
            showBullets: false,
            showButtons: false,
            showProgress: false
          }}
          // onBeforeChange={(nextStepIndex) => {
          //   return false;
          // }}
        />
        {/* <Hints enabled={hintsEnabled} hints={introHints} />       */}
        <Header showIntro={()=>{setIntroEnabled(true);}}/>
        <Body/>
        {/* <OnboardingTooltip
          open={(((widgetFnCount === 0 && !askDisplay) || (widgets.length === 0 && widgetFnCount > 0)) && datasources.length > 0) && modalStack.length === 0}
          placement = "left"
          arrow
          title = {
            (widgetFnCount === 0) ?
            <div className="onboard_message">Ask a question about your dataset.</div> :
            <div className="onboard_message">Add Widget to Dashboard</div>
          }
        > */}
          <Ask/>
        {/* </OnboardingTooltip> */}
        <Footer/>
      </div>
    </DemoIntroContext.Provider>)
     :
    (<Login />);
  return renderNode;
}

function Header(props: {showIntro: ()=>void}) {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(state => state.auth.email);
  const isUserDropdownVisible = useAppSelector(state => state.ui.isUserDropdownVisible);
  const handleLogout = () => {dispatch(logout())};
  const showUserDropdown = () => {dispatch(uiSlice.actions.showUserDropdown())};
  const hideUserDropdown = () => {dispatch(uiSlice.actions.hideUserDropdown())};
  const avatarRef = useRef(null);
  const photoUrl = auth.currentUser?.photoURL;

  return (
    <div className="demo_header_container" id="header">
      <div className="demo_header_logo"/>
      <div className="demo_header_spacer"></div>
      <div className="demo_header_help"><Help onClick={()=>props.showIntro()}/></div>
      <div className="demo_header_user" ref={avatarRef} onClick={showUserDropdown}>
        { photoUrl ? <Avatar src={photoUrl} sx={{width: 50, height: 50}}/> : 
        userEmail && <Gravatar email={userEmail} options={{size: 50}}>
          { (url: string) => (<Avatar src={url} sx={{width: 50, height: 50}}/>)}
        </Gravatar> }
      </div>
      <Popover
        open={isUserDropdownVisible}
        onClose={hideUserDropdown}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        anchorEl={avatarRef.current}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{ sx:
          {
            backgroundColor: 'rgba(127,127,127,0.1)',
            backdropFilter: 'blur(10px)',
            width: '150px',
            textAlign: 'right'
          }
        }}
      >
        <Button onClick={handleLogout}>Logout</Button>
      </Popover>
    </div>
  )
}

// const OnboardingTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Zoom} TransitionProps={{timeout: 1000}} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundImage: 'var(--gradient-1)',
//     animation: 'var(--animation-float) forwards',
//     color: 'rgba(255, 255, 255, 0.87)',
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//     boxShadow: 'var(--shadow-4)'
//   }
// }));

function Body() {
  const datasources = useAppSelector(state => state.dashi.datasources);
  // const modalStack = useAppSelector(state => state.ui.modalStack);
  return (
    <div className="demo_body" style={{width: "100vw", height: "100vh", display: "flex"}}>
      {/* <OnboardingTooltip
        open={(datasources.length === 0 && modalStack.length === 0)}
        placement = "bottom"
        arrow
        title = {
          <div className="onboard_message">Add a Dataset</div>
        }
      > */}
        <Datasources/>
      {/* </OnboardingTooltip> */}
      { datasources.length > 0 && <Drilldown/> }
    </div>    
  )
}

const Datasources = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const datasources = useAppSelector(state => state.dashi.datasources);
  const userid = useAppSelector(state => state.auth.userID) as string;
  const removeDatasourceHandler = (id: string) => {
    eventLogger.logEvent(LoggedInEventType.DASHI_REMOVE_DATASOURCES, { userid });
    dispatch(removeDatasource({id}));
  }

  return (
    <div ref={ref} className="dashi_datasources">
    {
      (datasources.length > 0) &&
        <div className="dashi_datasources_container">
          {datasources.map(ds => {
            return <DatasourceComponent key={ds.id} datasource={ds} removeHandler={()=>removeDatasourceHandler(ds.id)}/>
          })}
      </div>
    }
    <DatasourceUploader/>
    </div>
  )
});

function DatasourceComponent(props: {removeHandler: ()=>void, datasource: DatasourceDO}) {
  return (
    <Tooltip title={props.datasource.datasourceName} arrow>
    <Paper elevation={0} className={`dashi_datasource${props.datasource.datasourceName.includes('.xls')?' xls':''}`} key={props.datasource.id}>
      <Typography textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">{props.datasource.datasourceName}</Typography>
      <IconButton onClick={props.removeHandler}>
        <DeleteIcon/>
      </IconButton>
    </Paper>
    </Tooltip>
  )
}

function DatasourceUploader() {
  const dispatch = useAppDispatch();
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const userid = useAppSelector(state => state.auth.userID) as string;
  const {completeIntroStep} = React.useContext(DemoIntroContext);

  // handle drag events
  const handleDrag:DragEventHandler<HTMLElement> = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      if (e.dataTransfer.files) {
        const items =  e.dataTransfer.items;
        let acceptableFilesFound = false;
        const datasourcesDiv = document.querySelector('.dashi_datasources') as HTMLDivElement;
        for (let i = 0; i < items.length; i++) {
          console.log(items.length + 'files found');
          const file = items[i];
          if (file && ACCEPTABLE_FILE_TYPES.indexOf(file.type) >= 0) {
            console.log('acceptable files found');
            datasourcesDiv.style.backgroundColor = 'rgba(0, 200, 0, 0.5)';
            datasourcesDiv.style.filter = 'drop-shadow(0px 0px 10px rgba(0,0,0,0.5))';
            acceptableFilesFound = true;
            break;
          }
        }
        if (!acceptableFilesFound) {
          datasourcesDiv.style.backgroundColor = 'rgba(200, 0, 0, 0.5)';
          datasourcesDiv.style.filter = 'blur(5px)'
        }
      }
      setDragActive(true);
    } else if (e.type === "dragleave") {
      const datasourcesDiv = document.querySelector('.dashi_datasources') as HTMLDivElement;
      datasourcesDiv.removeAttribute('style');
      setDragActive(false);
    }
  };

  const handleFiles = async (files: FileList) => {
    const numFiles = files.length;
    const datasources: Datasource[] = []
    for (let i = 0; i < numFiles; i++) {
      const file = files.item(i);
      console.log('file.type:' + file?.type);
      if (file && file.type === 'text/csv') {
        datasources.push(new CsvDatasource(file.name, 'dashi', file.name, file));
      } else if (file && (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel')) {
        datasources.push(...(await XlsxUtils.getDatasources(file.name, 'dashi', file.name, file)));
      }
    }
    eventLogger.logEvent(LoggedInEventType.DASHI_ADD_DATASOURCES, {userid, type: 'file', count: datasources.length});
    if (datasources.length > 0) {
      dispatch(addDatasources({datasources}));
      completeIntroStep(DemoIntroStepName.ADD_DATASOURCES);
    }

  }

  // triggers when file is dropped
  const handleDrop:DragEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const datasourcesDiv = document.querySelector('.dashi_datasources') as HTMLDivElement;
    datasourcesDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.25)';
    datasourcesDiv.removeAttribute('style');
    setDragActive(false);
    if (e.dataTransfer.files) {
      eventLogger.logEvent(LoggedInEventType.DASHI_DROP_FILES, {userid, count: e.dataTransfer.files.length});
      await handleFiles(e.dataTransfer.files);
    }
  };

  const fileUploadHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('File Upload Handler');
    if (!event.target.files) {
      return;
    }
    await handleFiles(event.target.files);
    inputRef.current!.files = null;
  }

  const onButtonClick = () => {
    inputRef.current?.click();
  }

  return (
    <div className="dashi_datasource_uploader">
      <form onDragEnter={handleDrag} onDrop={handleDrop} onSubmit={(e) => e.preventDefault()} id="form-file-upload">
        <input type="file" ref={inputRef} id="input-file-upload" multiple={true} onChange={fileUploadHandler} hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
        <Button className="upload-button" onClick={onButtonClick} sx={{color: '#fff', fontSize: 'xx-large', width: '100%', height: '100%', flexDirection: 'column', display: 'flex'}}>
          <div style={{fontSize: 'larger'}}>+</div>
          <div className="upload-info">Add or drop CSV/XLS files.</div>
          <div className="upload-info"><small><b>PLEASE NOTE:</b> <i>We currently only support tabular data sheets with header rows.</i></small></div>
        </Button>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>
    </div>
  )
}

function Drilldown() {
  const dispatch = useAppDispatch();
  const widgets = useAppSelector(state => state.dashi.widgets);
  const suggestions = useAppSelector(state => state.ask.suggestions);
  const suggestStatus = useAppSelector(state => state.ask.suggestStatus);
  return (
    <div className="dashi_drilldown_container">
      {suggestStatus === 'pending' && <CircularProgress/>}
      {suggestions.map((s) => {
        const message: AskMessage = s.widget.info as AskMessage;
        return (
          <div className="dashi_widget" key={s.widget.id} style={{flexGrow: 1, width: '25%'}}>
          <DatayakiWidgetEl title={message.content.title} chartType={message.content.chart.type} widgetFn={getWidgetFunction(message.id)!} size={[1,1]} closeFn={()=>{dispatch(dashiSlice.actions.removeWidget({id: s.widget.id}))}}/>
          </div>
        )
      })}

      {widgets.map((w) => {
        const message: AskMessage = w.info as AskMessage;
        return (
          <div className="dashi_widget" key={w.id} style={{flexGrow: 1, width: '25%'}}>
          <DatayakiWidgetEl title={message.content.title} chartType={message.content.chart.type} widgetFn={getWidgetFunction(message.id)!} size={[1,1]} closeFn={() => {dispatch(dashiSlice.actions.removeWidget({id: w.id}))}}/>
          </div>
        )
      })}
    </div>
  )
}

export type DemoContext = {
  datasources: Map<string, any>,
  selectedDatasource: any,
}

function Footer() {
  return (
    <div className="footer">

      <FeedbackButton/>
      <div className="info-container">
        <PrivacyButton/>
        <VersionInfo/>
      </div>
    </div>
  )
}

function FeedbackButton() {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(uiSlice.actions.showModal(ModalType.FEEDBACK));
  }
  return (
    <div className="feedback-btn" onClick={handleClick}><Tooltip title="Give feedback!"><FeedbackOutlined  sx={{fontSize:"50px"}}/></Tooltip></div>
  )
}

function PrivacyButton() {
  return (
    <div className="privacy-btn"><Tooltip title="Legal"><a href="https://bit.ly/datayaki-legal" target='datayaki-legal'><BalanceOutlined sx={{fontSize:'small'}}/> {REACT_APP_COPYRIGHT}</a></Tooltip></div>
  )
}

function VersionInfo() {
  return (
    <Tooltip title={`v${REACT_APP_VERSION}`}>
      <div className="version-info">
        v{REACT_APP_VERSION}
      </div>
    </Tooltip>
  )
}
