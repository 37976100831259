import { createSlice, PayloadAction, Draft, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ASK_ENDPOINT, SUGGEST_ENDPOINT, auth } from '../../firebase';
import { RootState, store } from '../store';
import { DatasourceDO , DataframeDO, WidgetDO} from 'datayaki-api';
import { selectDashiId } from './dashi-slice';
import { DatayakiClient } from '../../api/client';
import { DatayakiWidget } from '../../dal/do/Widget';
interface AskState {
  dataContext: string,
  history: Message[],
  status: 'idle' | 'pending',
  suggestions: {question: string, widget: WidgetDO}[],
  suggestStatus: 'idle' | 'pending',
  error: string | null,
  enabled: boolean,
  display: boolean,
  widgetFnCount: number
}

const initialState: AskState = {
  dataContext: `datasets = {colHeaders: ['name','age'],records: [['Vijay',42],['Miranda',39],['Ivan',39]]}`,
  history: [],
  status: 'idle',
  suggestions: [],
  suggestStatus: 'idle',
  error: null,
  enabled: true,
  display: false,
  widgetFnCount: 0
};

export type ChartType = 'pie' | 'bar' | 'line' | 'scatter' | 'radar' | 'doughnut';

export type AskResponse = {
  messageId: string,
  type: 'prose' | 'question' | 'function' | 'error',
  title: string,
  result: string,
  chart: { type: ChartType | 'N/A', options: any }
}

export type SuggestResponse = {
  responses: {question: string, answer: AskResponse}[]
}

export interface Message {
  readonly sender: 'datayaki' | 'user' | 'system';
  readonly id: string;
  content: string | object;
}

export type LikeState = 'like' | 'dislike' | null;

export interface AskMessage extends Message {
  readonly sender: 'datayaki';
  likeState: LikeState;
  content: AskResponse;
};

export interface UserMessage extends Message {
  readonly sender: 'user';
  content: string;
}

export interface SystemMessage extends Message {
  readonly sender: 'system';
  content: string;
  type?: "info" | "warn" | "error";
}

export interface ToggleLikeRequest {
  messageId: string;
  likeState: LikeState;
}

export interface ExplainRequest {
  messageId?: string;
  explainMessageId: string;
}

export type ExplainResponse = {
  content: string
}
export interface UpdateDataContextRequest {
  datasources: DatasourceDO[];
}

export interface AskRequest {
  messageId?: string;
  content: string;
}

export const suggestQuestions = createAsyncThunk<
SuggestResponse,
void,
{ rejectValue: string }>(
  'ask/suggest',
  async (req: void, thunkApi) => {
    console.log(`Asking datayaki for suggestions`);
    try {
      const dataContext = selectAskDataContext(thunkApi.getState() as RootState);
      const authToken = await auth.currentUser?.getIdToken();
      const response = await axios.post(
        SUGGEST_ENDPOINT,
        { dataContext },
        { headers: {
          Authorization: 'Bearer '+authToken
        }}
      );
      return response.data;
    } catch(error) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  }
);


export const askQuestion = createAsyncThunk<
AskResponse,
AskRequest,
{ rejectValue: string }>(
  'ask/askQuestion',
  async (req: AskRequest, thunkApi) => {
    console.log(`Asking datayaki: ${req.content}`);
    try {
      if (!req.messageId || req.messageId === '') {
        req.messageId = crypto.randomUUID();
      }
      store.dispatch(askSlice.actions.addUserMessage(req));
      const dataContext = selectAskDataContext(thunkApi.getState() as RootState);
      const authToken = await auth.currentUser?.getIdToken();
      const response =
        await axios.post(
          ASK_ENDPOINT,
          {
            dataContext,
            question: req.content
          },
          {
            headers: {
              Authorization: 'Bearer '+authToken
            }
          });
      return response.data;
    } catch(error) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  }
);

export const explain = createAsyncThunk<
ExplainResponse,
ExplainRequest,
{ rejectValue: string }>(
  'ask/askQuestion',
  async (req: ExplainRequest, thunkApi) => {
    console.log(`Asking datayaki to explain: ${req.explainMessageId}`);
    try {
      const authToken = await auth.currentUser?.getIdToken();
      const dashiId = selectDashiId(thunkApi.getState() as RootState) as string;
      const response = await DatayakiClient.explain({
          dashiId: dashiId,
          messageId: req.messageId ?? crypto.randomUUID(),
          explainMessageId: req.explainMessageId
        }, authToken ?? '');
      return response.data;
    } catch(error: any) {
      return thunkApi.rejectWithValue((error as Error).message);
    }
  }
);

export const updateDataContext = createAsyncThunk<
  string,
  DatasourceDO[],
  { rejectValue: string }>(
    'ask/updateDataContext',
    async (datasources: DatasourceDO[], thunkApi) => {
      // store.dispatch(askSlice.actions.addSystemMessage({content: 'Schema updated'}));  
      return 'datasets = '+JSON.stringify(generateAskDataContext(datasources));
    }
  );

const widgetFunctions = new Map<string, (datasets: any) => any>();

export const getWidgetFunction = (messageId: string) => {
  return widgetFunctions.get(messageId);
}

export const askSlice = createSlice({
  name: 'ask',
  initialState,
  reducers: {
    toggleLike: (state, action: PayloadAction<ToggleLikeRequest>) => {
      const lookupMessage = state.history.find(x => x.id === action.payload.messageId && x.sender === 'datayaki') as (AskMessage | undefined);
      if (lookupMessage) {
        const currLikeState = lookupMessage.likeState;
        const messageIndex = state.history.indexOf(lookupMessage);
        (state.history[messageIndex] as Draft<AskMessage>).likeState = (action.payload.likeState === currLikeState) ? null : action.payload.likeState;
      }
    },
    addUserMessage: (state, action: PayloadAction<AskRequest>) => {
      state.history = [...state.history, {id: action.payload.messageId, sender: 'user', content: action.payload.content} as UserMessage];
    },
    addSystemMessage: (state, action: PayloadAction<AskRequest>) => {
      state.history = [...state.history, {id: crypto.randomUUID(), sender: 'system', content: action.payload.content} as SystemMessage];
    },
    toggleView: (state) => {
      state.display = !state.display;
    },
    setEnabled: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(askQuestion.pending, (state) => {
      state.status = 'pending';
      state.error = null;
    });
    builder.addCase(askQuestion.fulfilled, (state, { payload }) => {
      try {
        const messageId = payload.messageId ?? crypto.randomUUID();
        if (payload.type === 'function') {
          // eslint-disable-next-line no-new-func
          widgetFunctions.set(messageId, new Function('datasets', payload.result) as ((datasets: any) => any));
          state.widgetFnCount++;
        }
        state.history = [...state.history, { sender: 'datayaki', likeState: null, id: messageId, content: payload } as AskMessage];
        state.error = null;
      } catch (e: any) {
        state.error = e.message;
      }
      state.status = 'idle';
    });
    builder.addCase(askQuestion.rejected, (state, { payload}) => {
      state.error = payload ?? 'Unknown error';
      state.status = 'idle';
    });
    builder.addCase(updateDataContext.pending, () => {});
    builder.addCase(updateDataContext.fulfilled, (state, { payload }) => {
      state.dataContext = payload;
    });
    builder.addCase(updateDataContext.rejected, () => {});
    builder.addCase(suggestQuestions.pending, (state) => {state.suggestStatus = 'pending'});
    builder.addCase(suggestQuestions.fulfilled, (state, { payload }) => {
      try {
        let suggestions: {question: string, widget: WidgetDO}[] = [];
        payload.responses.map(
          r => { return {question:r.question, answer: {...r.answer, messageId: crypto.randomUUID()}}}
        ).forEach(r => {
          if (r.answer.type === 'function') {
            try {
              // eslint-disable-next-line no-new-func
              widgetFunctions.set(r.answer.messageId, new Function('datasets', r.answer.result) as ((datasets: any) => any));
              state.widgetFnCount++;
              suggestions = [...suggestions,
                {
                  question: r.question,
                  widget: (new DatayakiWidget(crypto.randomUUID(), 'dashi', 'userID', {id: r.answer.messageId, sender: 'datayaki', likeState: null ,content: r.answer})).toWidgetDO()
                }];
            } catch(_) {}
          }
        })
        state.suggestions = suggestions;
      } catch (_) {
      }
      state.suggestStatus = 'idle'}
    )
  }
});

function generateAskDataContext(datasources: DatasourceDO[]) {
  const datasets: {[key: string]: DataframeDO} = {};
  datasources.map(ds => datasets[ds.id] = ds.dataframeForContext);
  return datasets;
}

export const selectAskHistory = (state: RootState) => state.ask.history;
export const selectAskStatus = (state: RootState) => state.ask.status;
export const selectAskDataContext = (state: RootState) => state.ask.dataContext;
