export const secureFetch = async (input: RequestInfo | URL | string, init?: RequestInit ) => {
    // Encrypt request body if present
    if (init?.body) {
      console.log('Secure Fetch...')
      init.body = 'INTERCEPTED by SECURE FETCH';
    }

    const response = await fetch(input, init);

    // Decrypt response if present
    const encryptedResponse = await response.text();

    const decryptedResponse = JSON.parse(
      '{"hello":"secureFetch"}'
    );

    return decryptedResponse;

}